<template>
  <div class='abstract-submission-container flex flex-col lg:flex-row items-stretch min-h-screen'>
    <div
      class='w-full lg:w-80 px-8 pt-2 pb-8 lg:py-16 text-gray-50 flex flex-col items-start justify-start relative'
      :style='backgroundStyle'>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToMain'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        Back to Conference Site
      </h1>
      <h1 class='text-2xl lg:text-3xl font-light lg:mt-4 mb-8'>My Sessions</h1>
      <div v-for='session in mySessionsWithAbstracts'
        :key='`my-session-with-abstracts-${session.id}`'
        class='w-full flex flex-row justify-center mb-4 border rounded-md p-2 hover:opacity-100'
        :class='showingMySessionWithAbstractsSelectedClass(session)'>
        <button @click='selectShowingMySessionWithAbstracts(session)'>
          <div class='text-xs lg:text-sm'>
            {{sessionDateString(session.scheduledStartTime)}}
            {{sessionTimeString(session.scheduledStartTime, session.scheduledEndTime)}}
          </div>
          <div class='text-sm lg:text-base font-semibold'>{{session.title}}</div>
          <div class='text-xs lg:text-sm'>{{sessionLocationString(session.locations)}}</div>
        </button>
      </div>
      <button
        class='logout-button text-xs text-gray-50 uppercase hover:shadow-md border border-transparent hover:border-gray-50 rounded-md py-2 absolute'
        @click='logoutApp'>
        Logout
      </button>
    </div>
    <div class='p-8 lg:p-12 overflow-y-auto h-screen flex-grow'>
      <div class='max-w-4xl'>
        <h1 class='text-2xl font-semibold mb-4'>
          {{showingMySessionWithAbstracts.title}} ({{showingMySessionWithAbstractsCountString}})</h1>
        <h3>{{showingMySessionWithAbstractsDateString}} {{showingMySessionWithAbstractsTimeString}}</h3>
        <h3 class='mb-8'>{{showingMySessionWithAbstractsLocationString}}</h3>
        <abstract-submissions-of-my-sessions-row
          v-for='abstract in showingMySessionWithAbstracts.listOfAbstractSubmissions'
          :key='`my-abstract-${abstract.id}`'
          class='mb-4'
          :abstract='abstract' />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import { ArrowNarrowLeftIcon, } from 'vue-tabler-icons'
import AbstractSubmissionsOfMySessionsRow from '@/components/abstracts/AbstractSubmissionsOfMySessionsRow.vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'AbstractSubmissionsOfMySessions',
  components: {
    AbstractSubmissionsOfMySessionsRow,
    ArrowNarrowLeftIcon,
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
      'showingEventId',
    ]),
    ...mapState('abstracts', [
      'mySessionsWithAbstracts',
      'showingMySessionWithAbstracts',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    showingMySessionWithAbstractsLocationString () {
      return this.showingMySessionWithAbstracts.locations.map(location => location.split('||')[0]).join(', ')
    },
    showingMySessionWithAbstractsDateString () {
      return `${dayjs(this.showingMySessionWithAbstracts.scheduledStartTime).tz('Asia/Seoul').format('YYYY-MM-DD (ddd)')}`
    },
    showingMySessionWithAbstractsTimeString () {
      return `${dayjs(this.showingMySessionWithAbstracts.scheduledStartTime).tz('Asia/Seoul').format('HH:mm')} ~ ${dayjs(this.showingMySessionWithAbstracts.scheduledEndTime).tz('Asia/Seoul').format('HH:mm')}`
    },
    showingMySessionWithAbstractsCountString () {
      let abstractCount = this.showingMySessionWithAbstracts.listOfAbstractSubmissions.length
      return abstractCount > 1 ? `${abstractCount} Abstracts` : `${abstractCount} Abstract`
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'logout',
    ]),
    ...mapActions('abstracts', [
      'getMySessionsWithAbstracts',
      'selectShowingMySessionWithAbstracts',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToMain () {
      this.$router.push({name: 'Main'})
    },
    sessionLocationString (locations) {
      return locations.map(location => location.split('||')[0]).join(', ')
    },
    sessionDateString (startTime) {
      return `${dayjs(startTime).tz('Asia/Seoul').format('YYYY-MM-DD(ddd)')}`
    },
    sessionTimeString (startTime, endTime) {
      return `${dayjs(startTime).tz('Asia/Seoul').format('HH:mm')} ~ ${dayjs(endTime).tz('Asia/Seoul').format('HH:mm')}`
    },
    showingMySessionWithAbstractsSelectedClass (session) {
      return this.showingMySessionWithAbstracts.id === session.id ? 'opacity-100' : 'opacity-50'
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissionsOfMySessions'} })
      } else {
        this.getMySessionsWithAbstracts(this.showingEventId).then((resp) => {
          this.selectShowingMySessionWithAbstracts(resp[0])
        })
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
  .abstract-submission-container .logout-button {
    width: auto;
    @apply top-2 right-2;
  }

  @media (min-width: 1024px) {
    .abstract-submission-container .logout-button {
      width: calc(100% - 4rem);
      @apply top-auto right-auto bottom-4;
    }
  }
</style>
