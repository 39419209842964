<template>
  <div>
    <div class='border rounded-md'>
      <h1 class='font-semibold p-4'>{{ abstract.abstractNo }}</h1>
      <h2 class='mb-4 px-4'>{{ abstract.title }}</h2>
      <div class='flex flex-row justify-end bg-gray-100'>
        <button class='text-xs uppercase py-2 font-medium border-r border-l w-40'
          @click='toggleShowAbstractDetail'>
          {{ showAbstractDetailButtonText }} <component :is='showAbstractDetailButtonIcon' class='ml-2 inline-block' />
        </button>
        <button class='text-xs uppercase py-2 font-medium border-r w-72'
          :disabled='!abstract.oralExtendedAbstractFileUrl'
          @click='downloadExtendedAbstract'
          :class='downloadExtendedAbstractButtonClass'>
          {{ downloadExtendedAbstractButtonText }} <cloud-download-icon v-if='abstract.oralExtendedAbstractFileUrl' class='ml-2 inline-block' />
        </button>
        <button class='text-xs uppercase py-2 font-medium border-r w-72'
          :disabled='!abstract.oralPresentationFileUrl'
          @click='downloadPresentationFile'
          :class='downloadPresentationFileButtonClass'>
          {{ downloadPresentationFileButtonText }} <cloud-download-icon v-if='abstract.oralPresentationFileUrl' class='ml-2 inline-block' />
        </button>
      </div>
    </div>
    <abstract-submission-preview
      v-if='showAbstractDetail'
      :abstract='abstract'
      :show-accepted-status-badge='false'
      class='overflow-y-auto border rounded-md mb-8' />
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon, CloudDownloadIcon } from 'vue-tabler-icons'
import AbstractSubmissionPreview   from '@/components/abstracts/AbstractSubmissionPreview.vue'

export default {
  name: 'AbstractSubmissionsOfMySessionsRow',
  components: {
    AbstractSubmissionPreview,
    ChevronDownIcon,
    ChevronUpIcon,
    CloudDownloadIcon,
  },
  props: [
    'abstract',
  ],
  data() {
    return {
      showAbstractDetail: false
    }
  },
  computed: {
    showAbstractDetailButtonText () {
      return this.showAbstractDetail ? 'hide abstract' : 'show abstract'
    },
    showAbstractDetailButtonIcon () {
      return this.showAbstractDetail ? 'chevron-up-icon' : 'chevron-down-icon'
    },
    downloadExtendedAbstractButtonText () {
      return this.abstract.oralExtendedAbstractFileUrl ? 'download extended abstract' : 'no extended abstract'
    },
    downloadExtendedAbstractButtonClass () {
      return this.abstract.oralExtendedAbstractFileUrl ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
    },
    downloadPresentationFileButtonText () {
      return this.abstract.oralPresentationFileUrl ? 'download presentation file' : 'no presentation file'
    },
    downloadPresentationFileButtonClass () {
      return this.abstract.oralPresentationFileUrl ? 'opacity-100' : 'opacity-50 cursor-not-allowed'
    },
  },
  methods: {
    toggleShowAbstractDetail() {
      this.showAbstractDetail = !this.showAbstractDetail
    },
    downloadExtendedAbstract () {
      window.open(this.abstract.oralExtendedAbstractFileUrl, '_blank')
    },
    downloadPresentationFile () {
      window.open(this.abstract.oralPresentationFileUrl, '_blank')
    },
  },
}
</script>
